<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>
        <v-icon
          left
        >
          mdi-file-import-outline
        </v-icon>
        Mise à jour par lot
      </v-card-title>
      <v-card-text>
        <v-alert
          type="warning"
          prominent
          dense
        >
          Vous allez modifier {{ nbSelectedItems }} produits
        </v-alert>
        <v-form @submit.prevent="submit()">
          <v-row dense>
            <v-col cols="7">
              <CategorySelector v-model="form.category" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="7">
              <DropsSelector
                v-model="form.drops.values"
                @input="v => form.drops.eraseIfEmpty = v.length !== 0"
              />
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-checkbox
                v-if="form.drops.values.length === 0"
                v-model="form.drops.eraseIfEmpty"
                dense
                hide-details
                label="vider"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="7">
              <TargetSelector v-model="form.target" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="7">
              <FamilySelector v-model="form.family" />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row dense>
            <v-col cols="7">
              <v-textarea
                v-model="form.sizing.value"
                :label="$t('views.products.forms.input.content_sizing')"
                rows="1"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-checkbox
                v-if="!form.sizing.value"
                v-model="form.sizing.eraseIfEmpty"
                dense
                hide-details
                label="vider"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(attribute, index) in form.attributes"
            :key="attribute.kind"
            dense
          >
            <v-col cols="7">
              <AttributeSelector
                v-model="form.attributes[index].values"
                :kind="attribute.kind"
                @input="v => form.attributes[index].eraseIfEmpty = v.length !== 0"
              />
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-checkbox
                v-if="form.attributes[index].values.length === 0"
                v-model="form.attributes[index].eraseIfEmpty"
                dense
                hide-details
                label="vider"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading || !Object.keys(formData).length"
          raised
          color="red"
          class="white--text"
          @click="submit()"
        >
          Mettre à jour
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AttributeSelector from '@/components/base/Product/Input/AttributeSelector.vue'
import CategorySelector from '@/components/base/Product/Input/CategorySelector.vue'
import DropsSelector from '@/components/base/Product/Input/DropsSelector.vue'
import FamilySelector from '@/components/base/Product/Input/FamilySelector.vue'
import TargetSelector from '@/components/base/Product/Input/TargetSelector.vue'
import taskMixin from '@/mixins/task'

export default {
  name: 'BatchUpdateModal',
  components: { FamilySelector, TargetSelector, DropsSelector, AttributeSelector, CategorySelector },
  mixins: [taskMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    resolveSelectedItems: {
      type: Function,
      default: () => [],
    },
    nbSelectedItems: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        category: null,
        attributes: ['color', 'material', 'brand', 'state', 'years', 'pattern'].map(k => ({
          kind: k,
          values: [],
          eraseIfEmpty: false,
        })),
        drops: {
          values: [],
          eraseIfEmpty: false,
        },
        target: null,
        family: null,
        sizing: {
          value: null,
          eraseIfEmpty: false,
        },
      },
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
    formData () {
      const attributesByKind = this.form.attributes.map(a => {
        if (a.values.length === 0 && !a.eraseIfEmpty) {
          return null
        }

        return {
          kind: a.kind,
          values: a.values.map(v => ({
            name: v.name,
            taxonomy_id: v.taxonomy_id,
          })),
        }
      }).filter(v => !!v)

      const data = {
        category: this.form.category || null,
        drops: this.form.drops.eraseIfEmpty || this.form.drops.values.length !== 0 ?
          this.form.drops.values :
          null,
        target: this.form.target || null,
        family: this.form.family || null,
        attributesByKind: attributesByKind.length ? attributesByKind : null,
        sizing: this.form.sizing.value || (this.form.sizing.eraseIfEmpty ? '' : null),
      }

      // remove null values
      return Object.entries(data).reduce((a, [k, v]) => (v === null ? a : (a[k] = v, a)), {})
    },
  },
  methods: {
    close() {
      this.loading = false
      this.openedValue = false
      this.form.category = null
      this.form.attributes = this.form.attributes.map(v => {
        v.values = []
        v.eraseIfEmpty = false

        return v
      })
      this.form.drops = {
        values: [],
        eraseIfEmpty: false,
      }
      this.form.target = null
      this.form.family = null
      this.form.sizing = {
        value: null,
        eraseIfEmpty: false,
      }
    },
    async submit() {
      this.loading = true
      this.$axios.post(
        'v3/products/batch-update',
        {
          ids: await this.resolveSelectedItems(),
          ...this.formData,
        }
      ).then((response) => {
        this.taskCreated(response.data.id)
        this.$emit('success')
        this.close()
      }).catch((error) => {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
