<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>
        <v-icon
          left
        >
          mdi-file-import-outline
        </v-icon>
        Import prix
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="uploadAndImport()">
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-file-input
                v-model="file"
                outlined
                hide-details
                dense
                prepend-inner-icon="mdi-file-delimited-outline"
                :prepend-icon="null"
                accept="text/csv"
                label="Sélectionner le fichier à importer"
              />
            </v-col>
            <div class="pb-10">
              Colonnes attendues: product_id, variant_id, product_title, version_price, price, base_price
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading || file === null"
          raised
          color="green darken-1"
          class="white--text"
          @click="uploadAndImport()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import taskMixin from '@/mixins/task'

export default {
  name: 'ImportPricesModal',
  mixins: [taskMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      file: null,
    }
  },
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  methods: {
    close() {
      this.loading = false
      this.file = null
      this.openedValue = false
    },
    async uploadAndImport() {
      this.loading = true

      const formData = new FormData()
      formData.append('file', this.file)

      this.$axios.post('v3/products/import-prices', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((response) => {
        this.taskCreated(response.data.id)
      }).catch((error) => {
        this.taskFailed(error.response.data['hydra:description'] ?? error.response.data['detail'] ?? null)
      })
        .finally(
          this.close
        )
    },
  },
}
</script>

<style scoped>
</style>
